@import url(https://fonts.googleapis.com/css2?family=Akronim&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Dawning of a New Day", cursive;
  color: lightseagreen;
  background: #dfce9d;
}
h1 {
  font-size: 10rem;
  font-family: "Akronim", cursive;
  color: #e25822;
}
img {
  width: 50%;
  border-radius: 20px;
  opacity: 0.9;
}
.index {
  text-align: center;
}
.intro p {
  font-size: 4.5rem;
}
.index p {
  margin-bottom: 2%;
}
@media (max-width: 800px) {
  h1 {
    font-size: 8rem;
  }
}
@media (max-width: 500px) {
  h1 {
    font-size: 6rem;
  }
}
h2 {
  font-size: 7rem;
  font-family: "Hanalei", cursive;
}
@media (max-width: 800px) {
  h2 {
    font-size: 6rem;
  }
}
@media (max-width: 500px) {
  h2 {
    font-size: 5rem;
  }
}
div {
  font-size: 3.5rem;
}
@media (max-width: 800px) {
  div {
    font-size: 3rem;
  }
}
@media (max-width: 500px) {
  div {
    font-size: 2.5rem;
  }
}
.container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 800px) {
  .index {
    padding: 2%;
  }
}
@media (max-width: 500px) {
  .index {
    padding: 2%;
  }
}
nav {
  max-width: 100%;
  font-size: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 800px) {
  nav {
    font-size: 4rem;
  }
}
@media (max-width: 500px) {
  nav {
    font-size: 3rem;
  }
}
nav section {
  max-width: 50%;
  margin: 2%;
}
nav section a {
  text-decoration: none;
}
.btn {
  background-color: #1799b5;
  border-radius: 10%;
  padding: 10%;
  font-family: "Akronim", cursive;
  color: seashell;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rside {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  transform: rotate(15deg);
  margin: 5%;
}
@media (max-width: 800px) {
  .rside {
    margin: 8%;
    justify-content: center;
    align-content: center;
  }
}
@media (max-width: 500px) {
  .rside {
    justify-content: center;
    align-content: center;
    margin: 10%;
    transform: rotate(10deg);
  }
}
.lside {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  transform: rotate(-15deg);
  margin: 5%;
}
@media (max-width: 800px) {
  .lside {
    justify-content: center;
    align-content: center;
    margin: 12%;
  }
}
@media (max-width: 500px) {
  .lside {
    justify-content: center;
    align-content: center;
    margin: 15%;
  }
}
.ncard {
  background-color: lightcoral;
  border-radius: 10%;
  padding: 2%;
  color: seashell;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 50%;
}
@media (max-width: 800px) {
  .ncard {
    max-width: 65%;
  }
}
@media (max-width: 500px) {
  .ncard {
    max-width: 80%;
  }
}
a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
a img {
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

